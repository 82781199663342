import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  InputAdornment,
  Paper,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CustomTextField from "components/TextField";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AddMemberModal from "./_components/AddMemberModal";
import dummy from "../../assets/dummy/member.json";
import Axios from "axios";
import { useEffect } from "react";

const columns = [
  {
    field: "profile_picture",
    headerClassName: "header-font",
    headerName: "P",
    width: 50,
    renderCell: (params) => (
      <img
        alt="profile"
        src={params.value}
        style={{ height: "30px", width: "30px", borderRadius: "5px" }}
      />
    ),
  },
  {
    field: "full_name",
    headerClassName: "header",
    headerName: "Nama",
    width: 450,
  },
  {
    field: "nik",
    headerClassName: "header",
    headerName: "NIK",
    width: 176,
  },
  {
    field: "occupation",
    headerClassName: "header",
    headerName: "Profesi",
    width: 183,
  },
  {
    field: "createdAt",
    headerClassName: "header",
    headerName: "Tanggal Bergabung",
    width: 160,
    renderCell: ({ value }) => moment(value).format("DD/MM/YYYY"),
  },
];

const rows = dummy.data;

const Member = () => {
  const [pageSize, setPageSize] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [member, setMember] = useState()
  const navigate = useNavigate();
  useEffect(() => {
    Axios.get('http://staging-api-fkbn.fansnya.id/users').then(res => {
      console.log(res.data.result.users)
      let data = res.data.result.users
      setMember(data)
    })
  }, [])


  return (
    <Box flex="1">
      <AddMemberModal open={modalOpen} setModalOpen={setModalOpen} />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography
          fontSize="24px"
          color="#505E50"
          fontWeight="600"
          
        >
          Database Member
        </Typography>
        <Button
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#1247AF",
            fontFamily: "Inter",
          }}
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Tambah User
        </Button>
      </Stack>
      <Paper sx={{ paddingX: 3, height: "100%" }} >
        <Stack direction="row" justifyContent="space-between" paddingY={2}>
          <Box py={1}>
            <Button
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#CBD9F6",
                color: "#739AE7",
                width: "50px",
                borderRadius: "4px 0 0 4px",
                boxShadow: "none",
              }}
              variant="contained"
            >
              <GridViewIcon />
            </Button>
            <Button
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#1247AF",
                width: "50px",
                boxShadow: "none",
                borderRadius: "0 4px 4px 0",
              }}
              variant="contained"
            >
              <FormatListBulletedIcon />
            </Button>
          </Box>
          <Box py={1}>
            <CustomTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Cari Konten"
            />
          </Box>
        </Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#F9FAFA",
            },
            "& .header-font": {
              backgroundColor: "#F9FAFA",
              color: "#F9FAFA",
            },
          }}
        >
          <DataGrid
            rows={member}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(member) => member._id}
            header
            isRowSelectable={() => false}
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15]}
            onRowClick={() => navigate("/member/detail")}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default Member;
