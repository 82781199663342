import Member from "pages/Member";
import UserOverview from "pages/UserOverview";
import MemberDetail from "pages/Member/Detail";
import UserAnalyticDetail from "pages/Member/Detail/Analytic";

const root = "/member";

const user = [
  {
    path: `${root}/`,
    component: <Member />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/user-profiling`,
    component: <UserOverview />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/:userid`,
    component: <MemberDetail />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/analytic`,
    component: <UserAnalyticDetail />,
    sidebar: true,
    navbar: true,
  },
];

export default user;
