import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "components/Modal";
import dummy from "../../../assets/dummy/account.json";
import ClearIcon from "@mui/icons-material/Clear";
import trash from '../../../assets/trash-alt.png';

const account = dummy.data;

const RemoveMemberModal = ({ open, setModalOpen }) => {
  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 658 }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Hapus Akun
            </Typography>
            <Grid item>
              <ClearIcon sx={{cursor:"pointer"}} onClick={() => setModalOpen(false)} />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <Box
        sx={{
          p: 2,
        }}
      >
        <Stack direction="row" justifyContent="space-between" pb={2}>
          <Grid container spacing={1.5} paddingLeft={1} xs={12}>
            <Grid item xs={12} mt={1}>
              <Stack direction="row" mt={1}>
                <Grid item xs={2} mr={1}>
                  <img
                    src={trash}
                    alt=""
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack>
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        color="#6D7688"
                      >
                        Anda akan menghapus akun:
                      </Typography>
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        color="#235DD1"
                        mt={1}
                      >
                        <span style={{marginRight:"16px"}}>{account.full_name}</span>{account.email}
                      </Typography>
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        color="#6D7688"
                        mt={1}
                      >
                        Akun akan terhapus secara permanen dan tidak bisa dikembalikan.
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Box>
        <Divider />
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="right">
            <Button
              sx={{
                textTransform: "capitalize",
                marginRight: "16px",
              }}
              variant="outlined"
              onClick={() => setModalOpen(false)}
            >
              Batalkan
            </Button>
            <Button
              sx={{
                textTransform: "capitalize",
              }}
              variant="contained"
              color="error"
            >
              Simpan
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalCustom>
  );
};

export default RemoveMemberModal;
