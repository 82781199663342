import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Demography, Highlight, GrowthMetric } from "./_components";

const UserOverview = () => {
  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA" }} paddingX={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={1}
        paddingY={2}
      >
        <Typography fontSize="24px" color="#505E50" fontWeight="600">
          User Overview
        </Typography>
      </Stack>
      <Highlight />
      <GrowthMetric />
      <Demography />
    </Box>
  );
};

export default UserOverview;
