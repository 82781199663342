import { useEffect, useState } from "react";
import CKeditor from "./CKEditor";
import { Box, Button, Stack, Typography, Grid, Divider } from "@mui/material";
import CustomTextField from "components/TextField";
import axiosInstance from "utils/api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Compose = () => {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [description, setDescription] = useState("");
  const [media, setMedia] = useState(null);
  const [title, setTitle] = useState("");
  const [source, setSource] = useState("");
  const [highlight, setHighlight] = useState("");
  const token = JSON.parse(localStorage.getItem("folia"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();

  const handleClear = () => {
    setTitle("");
    setDescription("");
    setMedia(null);
    setSource("");
    setHighlight("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("title", title);
    data.append("highlight", highlight);
    data.append("source", source);
    data.append("description", description);
    data.append("blog_picture", media);

    axiosInstance
      .post("/blogs/create-blog", data, authentication)
      .then((res) => {
        console.log(res);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "The article has been successfully created!",
          showConfirmButton: true,
          timer: 2000,
        });
        setTimeout(() => {
          navigate("/content/published-content");
        }, 2000);

        handleClear();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong. Please try again.",
          showConfirmButton: true,
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <Box flex="1">
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Compose Article
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" justifyContent="space-between">
          <Grid
            paddingX={3}
            paddingY={2}
            sx={{ width: "50%", minHeight: "300px" }}
          >
            {/* first half */}
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="title"
                label="Title"
                placeholder="Insert a title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} paddingY={2}>
              <CustomTextField
                name="highlight"
                label="Highlight"
                placeholder="Insert a short highlight about this article"
                onChange={(e) => setHighlight(e.target.value)}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                <Typography
                  mb={1}
                  fontSize="16px"
                  fontWeight="400"
                  color="#5A5A5A"
                >
                  Description
                </Typography>
              </Box>
              <CKeditor
                name="description"
                onChange={(data) => {
                  setDescription(data);
                }}
                editorLoaded={editorLoaded}
              />
            </Grid>
          </Grid>

          {/* second half */}
          <Grid
            paddingRight={3}
            paddingY={2}
            sx={{ width: "50%", minHeight: "300px" }}
          >
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="source"
                label="Source"
                placeholder="Insert the source of this article"
                onChange={(e) => setSource(e.target.value)}
              />
            </Grid>
            <Stack paddingTop={2}>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                <Typography
                  mb={1}
                  fontSize="16px"
                  fontWeight="400"
                  color="#5A5A5A"
                >
                  Article's Picture
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <label htmlFor="profile_picture">
                  <input
                    style={{ display: "none" }}
                    id="profile_picture"
                    onChange={(e) => setMedia(e.target.files[0])}
                    name="profile_picture"
                    type="file"
                  />
                  <Box
                    padding={3}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px dashed #6D7688",
                      borderRadius: "5px",
                      backgroundColor: "#fafafa",
                      color: "#6D7688",
                      marginBottom: "20px",
                      width: "540px",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  >
                    {/* <AddIcon
                    sx={{
                      color: "#6D7688",
                      display: `${media != null ? "none" : null}`,
                    }}
                  /> */}
                    <Typography
                      sx={{
                        color: "#6D7688",
                        display: `${media != null ? "none" : null}`,
                      }}
                    >
                      Select picture
                    </Typography>
                    {media !== null ? (
                      <Stack
                        sx={{
                          padding: 1,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={window.URL.createObjectURL(media)}
                          width={300}
                          height="100%"
                          alt="media"
                          style={{ borderRadius: ".3rem" }}
                        />
                        <Button
                          variant="outlined"
                          sx={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFF",
                            mt: 2,
                            borderWidth: 2,
                          }}
                          onClick={() => setMedia(null)}
                        >
                          Change
                        </Button>
                        {/* <Typography fontSize="16px" fontWeight="500" mb={2}>
                          {media.name}
                        </Typography> */}
                      </Stack>
                    ) : null}
                  </Box>
                </label>
              </Box>
            </Stack>

            {/* <Grid item xs={12} paddingY={2}>
            <CustomTextField
              name="description"
              label="Description"
              placeholder="Insert a short description about this article"
              multiline
              rows={4}
            />
          </Grid> */}
          </Grid>
        </Stack>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                }}
                variant="outlined"
                onClick={handleClear}
              >
                Clear All
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#1247AF",
                  fontFamily: "Inter",
                }}
                variant="contained"
                type="submit"
              >
                Save and Publish
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Compose;
