import { EventList } from "pages";
import EventDetail from "pages/EventList/Detail";

const root = "/event";

const event = [
  {
    path: `${root}/`,
    component: <EventList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/:eventid`,
    component: <EventDetail />,
    sidebar: true,
    navbar: true,
  },
];

export default event;