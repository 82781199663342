import React, { useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FeedIcon from "@mui/icons-material/Feed";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import PostAddIcon from "@mui/icons-material/PostAdd";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import { useNavigate } from "react-router-dom";

const config = [
  {
    sectionTitle: "BLOG",
    items: [
      {
        icon: <FeedIcon />,
        label: "Article Database",
        navDestination: "/content/published-content",
      },
      {
        icon: <KeyboardIcon />,
        label: "Compose",
        navDestination: "/content/compose",
      },
    ],
  },
  {
    sectionTitle: "PRODUCTS",
    items: [
      {
        icon: <LocalFloristIcon />,
        label: "Plants",
        navDestination: "/products",
      },
      {
        icon: <PostAddIcon />,
        label: "Product Entry",
        navDestination: "/products/add-plant",
      },
    ],
  },
];

const SidebarSectionTitle = ({ children }) => (
  <Typography fontSize="10px" fontWeight="600" color="#FFF" marginLeft="16px">
    {children}
  </Typography>
);

const setJalan = (navigate, navDestination, setActive, label) => {
  navigate(navDestination);
  setActive(label);
};

const SidebarSectionItem = ({
  icon,
  label,
  navDestination,
  navigate,
  active,
  setActive,
}) => (
  <Stack
    onClick={() => setJalan(navigate, navDestination, setActive, label)}
    direction="row"
    alignItems="center"
    spacing={1}
    paddingY="12px"
    paddingLeft="16px"
    color="#FFF"
    sx={{
      ":hover": {
        backgroundColor: "#677A67",
        cursor: "pointer",
      },
      backgroundColor: active === label ? "#677A67" : "",
    }}
  >
    {icon}
    <Typography>{label}</Typography>
  </Stack>
);

const AdminSidebar = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(null);

  return (
    <Box
      width="240px"
      // borderRight="1px solid lightgray"
      overflow="scroll"
      paddingTop={10}
      sx={{
        backgroundColor: "#505E50",
        borderBottomRightRadius: "8px",
        minHeight: "100vh",
      }}
    >
      {config.map((section) => {
        return (
          <Stack justifyContent="center">
            <SidebarSectionTitle>{section.sectionTitle}</SidebarSectionTitle>
            {section.items.map((item) => {
              return (
                <SidebarSectionItem
                  icon={item.icon}
                  label={item.label}
                  navDestination={item.navDestination}
                  navigate={navigate}
                  setActive={setActive}
                  active={active}
                />
              );
            })}
          </Stack>
        );
      })}
    </Box>
  );
};

export default AdminSidebar;
