import { InputProduct, Products } from "pages";
import ProductDetails from "pages/Products/_components/ProductDetails";

const root = "/products";

const products = [
  {
    path: `${root}`,
    component: <Products />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/add-plant`,
    component: <InputProduct />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/:productId`,
    component: <ProductDetails />,
    sidebar: true,
    navbar: true,
  },

];

export default products;
