import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Paper } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/api/api";
import CreateAdmin from "./_components/CreateAdmin";
import Swal from "sweetalert2";

const columns = [
  {
    field: "full_name",
    headerClassName: "header",
    headerName: "Name",
    width: 600,
    flex: 1,
    renderCell: ({ value }) => <span style={{ paddingLeft: 6 }}>{value}</span>,
  },
  {
    field: "email",
    headerClassName: "header",
    headerName: "Email Address",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => <span style={{ paddingLeft: 6 }}>{value}</span>,
  },
  {
    field: "phone_number",
    headerClassName: "header",
    headerName: "Phone Number",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => <span style={{ paddingLeft: 6 }}>{value}</span>,
  },
  {
    field: "role",
    headerClassName: "header",
    headerName: "Assigned Roles",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => <span style={{ paddingLeft: 6 }}>{value}</span>,
  },
];

const AdminManagement = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const token = JSON.parse(localStorage.getItem("folia"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getAdmin = () => {
    axiosInstance
      .get("/admins/", authentication)
      .then((res) => {
        console.log(res);
        setAdmins(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deactivateAdmin = (adminId) => {
    console.log(adminId);
    Swal.fire({
      title: "Deactivate this admin?",
      text: "Once deactivated, you won't be able to see it",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Yes, proceed",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.patch(
          `admins/disable-admin/${adminId}`,
          {},
          authentication,
        );
        Swal.fire(
          "Done!",
          "This admin has been successfully deactivated",
          "success",
        );
        setTimeout(() => {
          getAdmin();
        }, 2000);
      }
    });
  };

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <Box flex="1">
      <CreateAdmin
        open={modalOpen}
        setModalOpen={setModalOpen}
        getAdmin={getAdmin}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Admin Management
        </Typography>
        <Button
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#677A67",
          }}
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Create Admin
        </Button>
      </Stack>
      <Paper sx={{ paddingX: 3, paddingY: 3, height: "100%" }}>
        <Stack>
          <Box
            sx={{
              "& .header": {
                backgroundColor: "#F9FAFA",
              },
              "& .header-font": {
                backgroundColor: "#F9FAFA",
                color: "#F9FAFA",
              },
            }}
          >
            <DataGrid
              rows={admins}
              columns={columns}
              pageSize={pageSize}
              autoHeight
              getRowId={(rows) => rows._id}
              header
              isRowSelectable={() => true}
              pagination
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 15, 25]}
              components={{
                Toolbar: GridToolbar,
              }}
              onRowClick={(rowId) => deactivateAdmin(rowId.id)}
            />
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

export default AdminManagement;
