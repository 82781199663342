import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/api/api";
import Swal from "sweetalert2";
import WestIcon from "@mui/icons-material/West";
// import EditIcon from "@mui/icons-material/Edit";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import moment from "moment";
import CustomTextField from "components/TextField";
import API_URL from "utils/api/base";

const ProductDetails = () => {
  const [details, setDetails] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("folia"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedSource, setEditedSource] = useState("");
  const [editedHighlight, setEditedHighlight] = useState("");
  const [media, setMedia] = useState(null);
  const [blogPic, setBlogPic] = useState("");

  const { productId } = useParams();

  // const blogImg = details?.blog_picture;

  useEffect(() => {
    getProductDetails();
    setEditorLoaded(true);
  }, []);

  const editSwitch = () => {
    setIsEdit(!isEdit);
    setIsDisabled(!isDisabled);
  };

  const getProductDetails = () => {
    axiosInstance
      .get(`/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDetails(res.data);
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("title", editedTitle);
    data.append("highlight", editedHighlight);
    data.append("source", editedSource);
    data.append("description", editedDescription);
    data.append("blog_picture", media);

    axiosInstance
      .put(`/blogs/${productId}`, data, authentication)
      .then((res) => {
        console.log(res);
        Swal.fire(
          "Done!",
          "The article has been successfully updated",
          "success",
        );
        editSwitch();
        getProductDetails();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire("Oops!", "Something went wrong", "error");
      });
  };

  const hideProduct = () => {
    Swal.fire({
      title: "Hide this product?",
      text: "Once hidden, you won't be able to see it",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Yes, proceed",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.patch(
          `/products/hide-product/${productId}`,
          {},
          authentication,
        );
        Swal.fire(
          "Done!",
          "The product has been successfully hidden",
          "success",
        );
        setTimeout(() => {
          navigate("/products/");
        }, 2000);
      }
    });
  };

  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA", paddingBottom: "40px" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Button
          startIcon={<WestIcon />}
          sx={{
            textTransform: "capitalize",
            color: "#6D7688",
            fontWeight: "400",
          }}
          onClick={() => navigate("/products/")}
        >
          Back
        </Button>
        <Stack direction="row">
          {/* <Button
            startIcon={<EditIcon />}
            sx={{
              textTransform: "capitalize",
              fontWeight: "400",
              marginRight: 1,
            }}
            variant="contained"
            color="info"
            onClick={() => editSwitch()}
          >
            Edit Article
          </Button> */}
          <Button
            startIcon={<VisibilityOffIcon />}
            sx={{
              textTransform: "capitalize",
              fontWeight: "400",
            }}
            variant="contained"
            color="error"
            onClick={(productId) => hideProduct(productId)}
          >
            Hide Product
          </Button>
        </Stack>
      </Stack>
      <Stack paddingX={3} paddingY={2} sx={{ fontFamily: "Murecho" }}>
        <Typography fontSize="24px" fontWeight="600" mb={1}>
          Product Details
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack
          paddingX={3}
          paddingBottom={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
          justifyContent="center"
        >
          {isEdit ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <label htmlFor="profile_picture">
                <input
                  style={{ display: "none" }}
                  id="profile_picture"
                  onChange={(e) => setMedia(e.target.files[0])}
                  name="profile_picture"
                  type="file"
                />
                <Box
                  padding={3}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px dashed #6D7688",
                    borderRadius: "5px",
                    backgroundColor: "#fafafa",
                    color: "#6D7688",
                    marginBottom: "20px",
                    width: "540px",
                    height: "100%",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      color: "#6D7688",
                      display: `${media != null ? "none" : null}`,
                    }}
                  >
                    Select picture
                  </Typography>
                  {media !== null ? (
                    <Stack
                      sx={{
                        padding: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={window.URL.createObjectURL(media)}
                        width={300}
                        height="100%"
                        alt="media"
                        style={{ borderRadius: ".3rem" }}
                      />
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#FFF",
                          mt: 2,
                          borderWidth: 2,
                        }}
                        onClick={() => setMedia(null)}
                      >
                        Change
                      </Button>
                    </Stack>
                  ) : null}
                </Box>
              </label>
            </Box>
          ) : (
            <img
              src={`${API_URL}${details?.product_picture}`}
              alt="Blog Image"
              crossorigin="anonymous"
              style={{
                // backgroundColor: `#10838E`,
                width: "500px",
                height: "100%",
              }}
            />
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ width: "130px", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Plant Name</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} xs={10}>
              <CustomTextField
                name="title"
                placeholder={`${details.title}`}
                onChange={(e) => setEditedTitle(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2}>
              <Typography>{details.name}</Typography>
            </Grid>
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ minWidth: "130px", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Price</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} xs={10}>
              <CustomTextField
                name="source"
                placeholder={`${details.highlight}`}
                multiline
                rows={4}
                onChange={(e) => setEditedHighlight(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2}>
              <Typography>Rp {details.price}</Typography>
            </Grid>
          )}
        </Stack>
        {isEdit === true ? null : (
          <Stack
            paddingX={3}
            paddingTop={2}
            sx={{ fontFamily: "Murecho" }}
            direction="row"
          >
            <Grid
              sx={{ width: "130px", borderRight: "2px solid #D3D3D3" }}
              color="#A1A1A1"
            >
              <Typography>Added date</Typography>
            </Grid>
            <Grid ml={2}>
              <Typography>{moment(details.createdAt).format("LL")}</Typography>
            </Grid>
          </Stack>
        )}
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ width: "130px", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Description</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} xs={10}>
              <CustomTextField
                name="source"
                placeholder={`${details.source}`}
                onChange={(e) => setEditedSource(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2}>
              <Typography>{details.description}</Typography>
            </Grid>
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ minWidth: "130px", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Highlight</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} xs={10}>
              <CustomTextField
                name="source"
                placeholder={`${details.highlight}`}
                multiline
                rows={4}
                onChange={(e) => setEditedHighlight(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2}>
              <Typography>{details.highlight}</Typography>
            </Grid>
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ minWidth: "130px", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Humidity</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} xs={10}>
              <CustomTextField
                name="source"
                placeholder={`${details.highlight}`}
                multiline
                rows={4}
                onChange={(e) => setEditedHighlight(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2}>
              <Typography>{details.humidity}</Typography>
            </Grid>
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ minWidth: "130px", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Feeding</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} xs={10}>
              <CustomTextField
                name="source"
                placeholder={`${details.highlight}`}
                multiline
                rows={4}
                onChange={(e) => setEditedHighlight(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2}>
              <Typography>{details.feed}</Typography>
            </Grid>
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ minWidth: "130px", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Temperature</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} xs={10}>
              <CustomTextField
                name="source"
                placeholder={`${details.highlight}`}
                multiline
                rows={4}
                onChange={(e) => setEditedHighlight(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2}>
              <Typography>{details.temperature}</Typography>
            </Grid>
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ minWidth: "130px", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Height & Growth</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} xs={10}>
              <CustomTextField
                name="source"
                placeholder={`${details.highlight}`}
                multiline
                rows={4}
                onChange={(e) => setEditedHighlight(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2}>
              <Typography>{details.height_n_growth_rate}</Typography>
            </Grid>
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ minWidth: "130px", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Watering</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} xs={10}>
              <CustomTextField
                name="source"
                placeholder={`${details.highlight}`}
                multiline
                rows={4}
                onChange={(e) => setEditedHighlight(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2}>
              <Typography>{details.watering}</Typography>
            </Grid>
          )}
        </Stack>
        {isEdit && (
          <Box>
            <Divider />
            <Box
              sx={{
                p: 2,
              }}
            >
              <Stack direction="row" justifyContent="right">
                <Button
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "16px",
                  }}
                  variant="outlined"
                  onClick={() => editSwitch()}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#1247AF",
                    fontFamily: "Inter",
                  }}
                  variant="contained"
                  type="submit"
                >
                  Update Article
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default ProductDetails;
