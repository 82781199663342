import React from "react";
import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { Tracker, SessionBreakdown } from "./DetailComponents";

const UserAnalyticDetail = () => {
  return (
    <>
      <Box flex="1" paddingX={1} sx={{ backgroundColor: "#F9FAFA" }} >
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={1}
          paddingY={2}
        >
          <Typography fontSize="24px" color="#505E50" fontWeight="600">
            User Analytic Detail
          </Typography>
        </Stack>
        <Tracker />
        <SessionBreakdown />
      </Box>
    </>
  );
};

export default UserAnalyticDetail;
