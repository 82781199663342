import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  Stack,
} from "@mui/material";
import {
  DeskripsiEvent,
  InformasiDasar,
  KategoriTiket,
  Partisipan,
} from "./AddEventMenu";
import ModalCustom from "components/Modal";
import ClearIcon from "@mui/icons-material/Clear";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AddEventModal = ({ open, setModalOpen, props }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{
        borderRadius: 2,
        width: 1000,
        maxHeight: "90%",
      }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Buat Event
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <Stack direction="row">
        <Box
          sx={{
            p: 0,
          }}
        >
          <Grid xs={12}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              textColor="inherit"
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                height: 400,
              }}
            >
              <Tab
                label="Informasi Dasar"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Virtual Event Setting"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Deskripsi Event"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Kategori Tiket"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab label="Partisipan" sx={{ textTransform: "capitalize" }} />
            </Tabs>
          </Grid>
        </Box>
        <Box
          sx={{
            p: 2,
            height: "400px",
            overflow: "scroll",
          }}
        >
          <Stack>
            <Grid xs={12}>
              <TabPanel value={value} index={0}>
                <InformasiDasar />
              </TabPanel>
              <TabPanel value={value} index={1}>
                Allocated for VIRTUAL EVENT SETTING
              </TabPanel>
              <TabPanel value={value} index={2}>
                <DeskripsiEvent />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <KategoriTiket />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Partisipan />
                {/* <KategoriTiket /> */}
              </TabPanel>
            </Grid>
          </Stack>
        </Box>
      </Stack>
      <Box>
        <Divider />
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="right">
            <Button
              sx={{
                textTransform: "capitalize",
                marginRight: "16px",
              }}
              variant="outlined"
              onClick={() => setModalOpen(false)}
            >
              Batalkan
            </Button>
            <Button
              sx={{
                textTransform: "capitalize",
              }}
              variant="contained"
            >
              Simpan
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalCustom>
  );
};

export default AddEventModal;
