import React from "react";
import { Box, Stack, Typography, Grid } from "@mui/material";

const Home = () => {
  const admin = JSON.parse(localStorage.getItem("admin_data"));
  return (
    <Box flex="1">
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography fontSize="24px" color="#505E50" fontWeight="600">
          Home
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "20px",
            backgroundColor: "#F9FAFA",
            paddingBottom: "100px",
            color: "#505E50",
          }}
        >
          <Typography fontSize="24px" fontWeight="600">
            Hi, {admin?.full_name}!
          </Typography>
          <Typography fontSize="20px" fontWeight="400">
            Welcome to Rumah Folia Dashboard
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
