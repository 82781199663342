import { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Divider,
  MenuItem,
} from "@mui/material";
import CustomTextField from "components/TextField";
import axiosInstance from "utils/api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const type = [
  {
    value: "Buy",
    label: "Buy",
  },
  {
    value: "Rent",
    label: "Rent",
  },
];

const InputProduct = () => {
  const [description, setDescription] = useState("");
  const [media, setMedia] = useState(null);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [watering, setWatering] = useState("");
  const [feed, setFeed] = useState("");
  const [temperature, setTemperature] = useState("");
  const [light, setLight] = useState("");
  const [humidity, setHumidity] = useState("");
  const [heightAndGrowth, setHeightAndGrowth] = useState("");
  const [highlight, setHighlight] = useState("");
  const [sellingType, setSellingType] = useState("Buy");
  const token = JSON.parse(localStorage.getItem("folia"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();

  const handleClear = () => {
    // setTitle("");
    setDescription("");
    setMedia(null);
    // setSource("");
    setHighlight("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("name", name);
    data.append("highlight", highlight);
    data.append("price", price);
    data.append("watering", watering);
    data.append("feed", feed);
    data.append("temperature", temperature);
    data.append("light", light);
    data.append("humidity", humidity);
    data.append("height_n_growth_rate", heightAndGrowth);
    data.append("description", description);
    data.append("product_picture", media);
    data.append("type_sell", sellingType);

    axiosInstance
      .post("/products/create-product", data, authentication)
      .then((res) => {
        console.log(res);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "The product has been added successfully!",
          showConfirmButton: true,
          timer: 2000,
        });
        setTimeout(() => {
          navigate("/products/");
        }, 2000);

        handleClear();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong. Please try again.",
          showConfirmButton: true,
          timer: 2000,
        });
      });
  };

  return (
    <Box flex="1">
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Product Entry
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" justifyContent="space-between">
          <Grid
            paddingX={3}
            paddingY={2}
            sx={{ width: "50%", minHeight: "300px" }}
          >
            {/* first half */}
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="selling"
                label="Selling Group"
                placeholder="Please select a selling type"
                select
                value={sellingType}
                onChange={(e) => setSellingType(e.target.value)}
              >
                {type.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="name"
                label="Product Name"
                placeholder="Insert product name"
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} paddingY={2}>
              <CustomTextField
                name="highlight"
                label="Highlight"
                placeholder="Insert a short highlight about this product"
                onChange={(e) => setHighlight(e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="description"
                label="Description"
                placeholder="Insert more details about this product"
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="watering"
                label="Watering Information"
                placeholder="Insert watering instruction of the product"
                onChange={(e) => setWatering(e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="temperature"
                label="Temperature Information"
                placeholder="Insert temperature instruction of the product"
                onChange={(e) => setTemperature(e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
          </Grid>

          {/* second half */}
          <Grid
            paddingRight={3}
            paddingY={2}
            sx={{ width: "50%", minHeight: "300px" }}
          >
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="price"
                label="Price"
                placeholder="Insert the price of the product"
                onChange={(e) => setPrice(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="light"
                label="Lighting Information"
                placeholder="Insert lighting instruction of the product"
                onChange={(e) => setLight(e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="humidity"
                label="Humidity Information"
                placeholder="Insert humidity instruction of the product"
                onChange={(e) => setHumidity(e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="feed"
                label="Feeding Information"
                placeholder="Insert feeding instruction of the product"
                onChange={(e) => setFeed(e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="heightAndGrowthRate"
                label="Height and Growth Information"
                placeholder="Insert height and growth information of the product"
                onChange={(e) => setHeightAndGrowth(e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Stack paddingTop={2}>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                <Typography
                  mb={1}
                  fontSize="16px"
                  fontWeight="400"
                  color="#5A5A5A"
                >
                  Product's Picture
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <label htmlFor="profile_picture">
                  <input
                    style={{ display: "none" }}
                    id="profile_picture"
                    onChange={(e) => setMedia(e.target.files[0])}
                    name="profile_picture"
                    type="file"
                  />
                  <Box
                    padding={3}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px dashed #6D7688",
                      borderRadius: "5px",
                      backgroundColor: "#fafafa",
                      color: "#6D7688",
                      marginBottom: "20px",
                      width: "540px",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  >
                    {/* <AddIcon
                    sx={{
                      color: "#6D7688",
                      display: `${media != null ? "none" : null}`,
                    }}
                  /> */}
                    <Typography
                      sx={{
                        color: "#6D7688",
                        display: `${media != null ? "none" : null}`,
                      }}
                    >
                      Select picture
                    </Typography>
                    {media !== null ? (
                      <Stack
                        sx={{
                          padding: 1,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={window.URL.createObjectURL(media)}
                          width={300}
                          height="100%"
                          alt="media"
                          style={{ borderRadius: ".3rem" }}
                        />
                        <Button
                          variant="outlined"
                          sx={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFF",
                            mt: 2,
                            borderWidth: 2,
                          }}
                          onClick={() => setMedia(null)}
                        >
                          Change
                        </Button>
                        {/* <Typography fontSize="16px" fontWeight="500" mb={2}>
                          {media.name}
                        </Typography> */}
                      </Stack>
                    ) : null}
                  </Box>
                </label>
              </Box>
            </Stack>

            {/* <Grid item xs={12} paddingY={2}>
            <CustomTextField
              name="description"
              label="Description"
              placeholder="Insert a short description about this article"
              multiline
              rows={4}
            />
          </Grid> */}
          </Grid>
        </Stack>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                }}
                variant="outlined"
                onClick={handleClear}
              >
                Clear All
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#1247AF",
                  fontFamily: "Inter",
                }}
                variant="contained"
                type="submit"
              >
                Add Product
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default InputProduct;
