import React from "react";
import {
  Box,
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Chart from "react-apexcharts";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ViewIcon from "@mui/icons-material/Visibility";
import dummy from "../../../../assets/dummy/userAnalytic.json";

const analyticData = dummy.data;
const dataSeries = analyticData.series;
const topics = analyticData.topics;

const options = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  title: {
    text: "",
    align: "left",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
  },
};

const Tracker = () => {
  return (
    <>
      <Stack justifyContent="space-between" padding={1}>
        <Stack direction="row" justifyContent="space-between">
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingY={2}
            mr={1}
            sx={{
              backgroundColor: "#FFF",
              height: "100%",
              borderRadius: "5px",
            }}
          >
            <Box sx={{ width: "480px", height: "350px" }}>
              <Chart options={options} series={dataSeries} />
            </Box>
            <Stack paddingRight={1}>
              <Typography fontSize="14px" color="#000" fontWeight="400" mt={4}>
                Total Duration
              </Typography>
              <Typography fontSize="24px" color="#000" fontWeight="500" mb={1}>
                {analyticData.totalDuration}
              </Typography>
              <Typography fontSize="14px" color="#000" fontWeight="400">
                Session
              </Typography>
              <Typography fontSize="24px" color="#000" fontWeight="500" mb={1}>
                {analyticData.session}
              </Typography>
              <Typography fontSize="14px" color="#000" fontWeight="400">
                Avg. Engagement/Session
              </Typography>
              <Typography fontSize="24px" color="#000" fontWeight="500">
                {analyticData.averageEngagement}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Box
              sx={{
                height: "auto",
                width: "350px",
                borderRadius: "5px",
                backgroundColor: "#F9FAFA",
              }}
              mb={1}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#FFF",
                    border: "1px solid #DDDFE4",
                    borderRadius: "5px",
                  }}
                >
                  <TableRow sx={{ backgroundColor: "#F9FAFA" }}>
                    <TableCell>
                      <Typography
                        fontSize="14px"
                        color="#292C33"
                        fontWeight="600"
                      >
                        Interaction
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontSize="12px"
                        color="#6D7688"
                        fontWeight="600"
                        sx={{
                          textTransform: "uppercase",
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    backgroundColor: "#FFF",
                    border: "1px solid #DDDFE4",
                    borderRadius: "5px",
                  }}
                >
                  <TableRow>
                    <TableCell>
                      <Stack direction="row">
                        <ViewIcon sx={{ color: "#C1C5CD" }} />
                        <Typography ml={1} sx={{ color: "#1247AF" }}>
                          Content View
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography ml={2}>{analyticData.contentView}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Stack direction="row">
                        <FavoriteIcon sx={{ color: "#C1C5CD" }} />
                        <Typography ml={1} sx={{ color: "#1247AF" }}>
                          Content Like
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography ml={2}>{analyticData.contentLike}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Stack direction="row">
                        <ShareIcon sx={{ color: "#C1C5CD" }} />
                        <Typography ml={1} sx={{ color: "#1247AF" }}>
                          Content Share
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography ml={2}>
                        {analyticData.contentShare}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box
              sx={{
                height: "auto",
                width: "350px",
                borderRadius: "5px",
                backgroundColor: "#F9FAFA",
              }}
              mb={1}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#FFF",
                    border: "1px solid #DDDFE4",
                    borderRadius: "5px",
                  }}
                >
                  <TableRow sx={{ backgroundColor: "#F9FAFA" }}>
                    <TableCell>
                      <Typography
                        fontSize="14px"
                        color="#292C33"
                        fontWeight="600"
                      >
                        Topic of Interest
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontSize="12px"
                        color="#6D7688"
                        fontWeight="600"
                        sx={{
                          textTransform: "uppercase",
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    backgroundColor: "#FFF",
                    border: "1px solid #DDDFE4",
                    borderRadius: "5px",
                  }}
                >
                  {topics.map((item) => (
                    <TableRow id={item.id}>
                      <TableCell>
                        <Typography sx={{ color: "#1247AF" }}>
                          {item.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.value}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Tracker;
