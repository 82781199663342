import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import CustomTextField from "components/TextField";
import imageUpload from "../../../../assets/image-upload.png";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

const DeskripsiEvent = () => {
  return (
    <>
      <Box sx={{width: 750}}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography mb={2} sx={{ fontWeight: "600", fontSize: "16px" }}>
                Foto Utama Event
              </Typography>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                padding: "20px",
                border: "3px dashed #eeeeee",
                borderRadius: "4px",
                backgroundColor: "#fafafa",
                color: "#bdbdbd",
                marginBottom: "20px",
              }}
            >
              <img src={imageUpload} alt="" style={{ width: "94px" }} />
              <Typography mb={2}>Geser gambar ke kotak</Typography>
              <Typography mb={2}>atau</Typography>
              <Button
                mb={2}
                variant="outlined"
                sx={{ textTransform: "capitalize", backgroundColor: "#FFF" }}
                startIcon={<FolderOpenIcon />}
              >
                Buka Folder
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="description"
              label="Deskripsi"
              placeholder="Masukkan deskripsi event"
              multiline
              rows={10}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DeskripsiEvent;
