import {
  Grid,
  Container,
  InputAdornment,
  Button,
  Typography,
  Box,
} from "@mui/material";
import CustomTextField from "components/TextField";
import React, { useState, useEffect } from "react";
import LoginImage from "../../assets/LoginImage.png";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import Header from "./_components/Header";
import { useNavigate } from "react-router-dom";
// import Axios from "axios";
import UserInvalidModal from "./_components/UserInvalidModal";
import axiosInstance from "utils/api/api";
import Loader from "components/Loader";

const Login = (props) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (isSubmiting) {
      if (Object.keys(errors).length === 0) {
        submitLogin();
      } else {
        setIsSubmiting(false);
      }
    }
  }, [errors]);

  const submitLogin = () => {
    axiosInstance
      .post(`/admins/login-admin`, form)
      .then((res) => {
        console.log(res);
        localStorage.setItem("admin_data", JSON.stringify(res.data.admin));
        localStorage.setItem("folia", JSON.stringify(res.data.access_token));
        return window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setModalOpen(true);
        setIsSubmiting(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errs = validate();
    setErrors(errs);
    setIsSubmiting(true);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({ ...form, [id]: value });
  };

  const validate = () => {
    let err = {};
    if (!form.email) {
      err.email = "email required";
    }
    if (!form.password) {
      err.password = "password required";
    }
    return err;
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#809480",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          boxSizing: "",
          margin: 0,
          padding: 0,
          minHeight: "100vh",
          minWidth: "100vw",
          overflow: "hidden",
          whitespace: "nowrap",
        }}
      >
        <UserInvalidModal open={modalOpen} setModalOpen={setModalOpen} />

        <Container>
          {isSubmiting ? (
            <Box>
              <Grid
                container
                direction={`row`}
                justify={`center`}
                sx={{ minHeight: "100vh" }}
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <Loader />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                paddingY: "15%",
                paddingLeft: "25%",
                // justifyContent: "center"
              }}
            >
              {/* <Grid item xs={12} md={6}>
                <img src={LoginImage} alt="" srcset="" />
              </Grid> */}
              <Grid
                item
                xs={12}
                md={6}
                padding={4}
                sx={{
                  width: "100%",
                  backgroundColor: "#677A67",
                  borderRadius: "25px",
                  border: "1px solid #677A67",
                }}
              >
                <Header />
                {/*Form  */}
                <form onSubmit={handleSubmit}>
                  <Grid item xs={12} mb={2}>
                    <CustomTextField
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      label={errors.email ? errors.email : ""}
                      error={errors.email ? "error" : ""}
                      placeholder="Email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon
                              sx={{ color: "#FFF", marginRight: "4px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <CustomTextField
                      value={form.password}
                      name="password"
                      onChange={handleChange}
                      placeholder="Password"
                      error={errors.password ? "error" : ""}
                      label={errors.password ? errors.password : ""}
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <KeyIcon
                              sx={{ color: "#FFF", marginRight: "4px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: "24px", cursor: "pointer" }}
                  >
                    <Typography
                      sx={{
                        color: "#1247AF",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Lupa Password ?
                    </Typography>
                  </Grid> */}
                  <Grid item xs={12} mb={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#FFF",
                        color: "#677A67",
                        width: "100%",
                        border: "#677A67",
                        borderWidth: "1px",
                      }}
                    >
                      Login
                    </Button>
                  </Grid>
                </form>
                {/* end Form */}
              </Grid>
            </Box>
          )}
        </Container>
      </div>
    </>
  );
};

export default Login;
