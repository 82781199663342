import React, { useState } from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import dummy from "../../assets/dummy/event.json";
import AddEventModal from "./_components/AddEventModal";

const columns = [
  {
    field: "date",
    headerClassName: "header",
    headerName: "Event date",
    flex: 4,
    renderCell: ({ value }) => moment(value).format("LL"),
  },
  {
    field: "title",
    headerClassName: "header",
    headerName: "Nama Kegiatan",
    width: 650,
  },
  {
    field: "registered",
    headerClassName: "header",
    headerName: "Registered",
    width: 170,
    renderCell: ({ value }) => (
      <div style={{ display: "grid", gridTemplateRows: "15px" }}>
        <div style={{ width: "50px" }}>
          <span style={{ fontSize: "12px" }}>{value}/200</span>
        </div>
        <div>
          <progress
            value={value}
            min="0"
            max="200"
            style={{ width: "140px" }}
          />
        </div>
      </div>
    ),
  },
];

const rows = dummy.data;

const ActiveEvents = () => {
  const [pageSize, setPageSize] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Box flex="1">
      <AddEventModal open={modalOpen} setModalOpen={setModalOpen} />
      <Box
        sx={{
          "& .header": {
            backgroundColor: "#F9FAFA",
          },
          "& .header-font": {
            backgroundColor: "#F9FAFA",
            color: "#F9FAFA",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          autoHeight
          header
          isRowSelectable={() => false}
          pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15]}
          onRowClick={() => navigate("/event/detail")}
        />
      </Box>
    </Box>
  );
};

export default ActiveEvents;
