import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Typography,
  Stack,
  Switch,
} from "@mui/material";
import CustomTextField from "components/TextField";
import AddIcon from "@mui/icons-material/Add";

const Partisipan = () => {
  return (
    <>
      <Box paddingX={2}>
        <Grid container spacing={1.5}>
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ backgroundColor: "#F9FAFA", borderRadius: "8px" }}
              p={1}
            >
              <Grid item xs={6} mt={1} ml={2}>
                <Typography
                  sx={{ fontWeight: "400", fontSize: "14px", color: "#6D7688" }}
                >
                  1 akun - 1 kali pembelian
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Switch defaultChecked />
              </Grid>
            </Stack>
            <Grid item xs={6} mt={2}>
              <Typography
                sx={{ fontWeight: "600", fontSize: "16px", color: "#555D6B" }}
              >
                Pembelian
              </Typography>
            </Grid>
            <Stack direction="row" mt={2}>
              <Grid item xs={6} mr={1}>
                <CustomTextField name="min" label="Minimum" placeholder="0" />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField name="max" label="Maksimum" placeholder="0" />
              </Grid>
            </Stack>
            <Grid item xs={6} mt={4}>
              <Typography
                sx={{ fontWeight: "600", fontSize: "16px", color: "#555D6B" }}
              >
                Kumpulkan Data Peserta
              </Typography>
            </Grid>
            <Stack direction="row" mt={2}>
              <Grid item xs={4} mr={1}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Nama Lengkap"
                  />
                  <FormControlLabel control={<Checkbox />} label="Email" />
                  <FormControlLabel control={<Checkbox />} label="Nomor HP" />
                </FormGroup>
              </Grid>
              <Grid item xs={8}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Nomor Induk Kependudukan"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Tanggal Lahir"
                  />
                  <FormControlLabel control={<Checkbox />} label="Profesi" />
                </FormGroup>
              </Grid>
            </Stack>
            <Typography
              mt={4}
              sx={{ fontWeight: "600", fontSize: "16px", color: "#555D6B" }}
            >
              Dokumen Lampiran
            </Typography>
            <Typography
              mt={1}
              sx={{ fontWeight: "400", fontSize: "14px", color: "#6D7688" }}
            >
              Peserta akan diminta untuk mengunggah dokumen lampiran untuk
              megikuti kegiatan
            </Typography>
            <Box mt={1}>
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                sx={{ textTransform: "capitalize", fontSize: "14px" }}
              >
                Tambah Dokumen
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Box>
    </>
  );
};

export default Partisipan;
