import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Activity, Interaction, Purchasing, PersonalData } from "./DetailComponents";
import RemoveMemberModal from "../_components/RemoveMemberModal";
import { useNavigate } from "react-router-dom";

const MemberDetail = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA" }}>
      <RemoveMemberModal open={modalOpen} setModalOpen={setModalOpen} />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography fontSize="24px" color="#505E50" fontWeight="600">
          Detail Member
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            sx={{
              textTransform: "capitalize",
              borderColor: "#EB5757",
              backgroundColor:"#FFF",
              color: "#40454F",
            }}
            variant="outlined"
            onClick={() => setModalOpen(true)}
          >
            Hapus Member
          </Button>
          <Button
            sx={{ textTransform: "capitalize", backgroundColor: "#1247AF" }}
            variant="contained"
          >
            Edit Data
          </Button>
          <Button
            sx={{ textTransform: "capitalize", backgroundColor: "#1247AF" }}
            variant="contained"
            onClick={() => navigate("/member/analytic")}
          >
            Analytic Details
          </Button>
        </Stack>
      </Stack>
      <PersonalData />
      <Activity />
      <Interaction />
      <Purchasing />
    </Box>
  );
};

export default MemberDetail;
