import React from "react";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Chart from "react-apexcharts";
import EastIcon from "@mui/icons-material/East";
import heatmap from "../../../assets/dummy/peakHour.json";
import dummy from "../../../assets/dummy/demographyData.json";
import provider from "../../../assets/dummy/provider.json";

const series = heatmap.series;

const demography = dummy.data;

const providerData = provider.data;

const options = {
  plotOptions: {
    heatmap: {
      shadeIntensity: 0.5,
      radius: 2,
      enableShades: false,
      useFillColorAsStroke: false,
      distributed: true,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 200,
            name: "Low",
            color: "#CBD9F6",
          },
          {
            from: 201,
            to: 400,
            name: "Normal",
            color: "#487BE0",
          },
          {
            from: 401,
            to: 600,
            name: "Medium",
            color: "#235DD1",
          },
          {
            from: 601,
            to: 800,
            name: "High",
            color: "#1247AF",
          },
          {
            from: 801,
            to: 1000,
            name: "Extreme",
            color: "#15377A",
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
  },
  title: {
    text: "",
  },
};

const bar = {
  series: [
    {
      name: "Males",
      data: [6, 12, 14, 16, 19],
    },
    {
      name: "Females",
      data: [-8, -15, -16, -16, -17],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 400,
      stacked: true,
    },
    colors: ["#B870FF", "#EB5757"],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "20%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -20,
      max: 20,
      title: {
        text: "Age",
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val;
        },
      },
      y: {
        formatter: function (val) {
          return Math.abs(val) + " people";
        },
      },
    },
    title: {
      text: "",
    },
    xaxis: {
      categories: ["65+", "50-65", "41-50", "31-40", "20-30"],
      title: {
        text: "People",
      },
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val));
        },
      },
    },
  },
};

const Demography = () => {
  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA" }} paddingX={1}>
      <Typography fontSize="16px" color="#292C33" fontWeight="500" mb={1}>
        Demography
      </Typography>
      <Stack>
        <Stack direction="row" justifyContent="space-around" mb={1}>
          {demography.map((item) => (
            <Box
              sx={{
                borderRadius: "4px",
                backgroundColor: "#FFF",
                width: "330px",
              }}
              id={item.id}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                paddingX={2}
                paddingY={1}
              >
                <Typography
                  fontSize="14px"
                  color="#292C33"
                  fontWeight="600"
                  sx={{ paddingTop: "5px" }}
                >
                  {item.title}
                </Typography>
              </Stack>
              <Divider />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        fontSize="12px"
                        color="#6D7688"
                        fontWeight="600"
                        sx={{
                          textTransform: "uppercase",
                        }}
                      >
                        {item.head}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontSize="12px"
                        color="#6D7688"
                        fontWeight="600"
                        sx={{
                          textTransform: "uppercase",
                        }}
                      >
                        users
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.data.map((i) => (
                    <TableRow id={i.id}>
                      <TableCell>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateRows: "20px",
                          }}
                        >
                          <div>
                            <span style={{ fontSize: "14px" }}>{i.name}</span>
                          </div>
                          <div>
                            <progress
                              value={i.value}
                              min="0"
                              max="1000"
                              style={{ width: "200px", height: "15px" }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{i.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Stack direction="row" justifyContent="right" padding={1}>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    border: "none",
                    color: "#15377A",
                    fontSize: "14px",
                  }}
                  endIcon={<EastIcon />}
                  variant="outlined"
                >
                  Detail
                </Button>
              </Stack>
            </Box>
          ))}
        </Stack>
        <Stack direction="row" justifyContent="space-around" mb={2}>
          <Box>
            <Box
              p={1}
              sx={{
                backgroundColor: "#FFF",
                height: "auto",
                borderRadius: "4px",
              }}
            >
              <Stack>
                <Typography
                  fontSize="14px"
                  color="#292C33"
                  fontWeight="600"
                  p={1}
                >
                  Age Group
                </Typography>
                <Divider />
                <Chart
                  series={bar.series}
                  options={bar.options}
                  type="bar"
                  height="290"
                  width="300"
                />
              </Stack>
              <Divider />
              <Stack direction="row" justifyContent="right" padding={1}>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    border: "none",
                    color: "#15377A",
                    fontSize: "14px",
                  }}
                  endIcon={<EastIcon />}
                  variant="outlined"
                >
                  Detail
                </Button>
              </Stack>
            </Box>
          </Box>
          <Box>
            <Box
              p={1}
              sx={{
                backgroundColor: "#FFF",
                height: "auto",
                borderRadius: "4px",
              }}
            >
              {providerData.map((item) => (
                <Box
                  sx={{
                    borderRadius: "4px",
                    width: "auto",
                    backgroundColor: "#FFF",
                  }}
                  id={item.id}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    paddingX={2}
                    paddingY={1}
                  >
                    <Typography
                      fontSize="14px"
                      color="#292C33"
                      fontWeight="600"
                    >
                      {item.title}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography
                            fontSize="12px"
                            color="#6D7688"
                            fontWeight="600"
                            sx={{
                              textTransform: "uppercase",
                            }}
                          >
                            {item.head}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            fontSize="12px"
                            color="#6D7688"
                            fontWeight="600"
                            sx={{
                              textTransform: "uppercase",
                            }}
                          >
                            users
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.data.map((i) => (
                        <TableRow id={i.id}>
                          <TableCell>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateRows: "20px",
                              }}
                            >
                              <div>
                                <span style={{ fontSize: "14px" }}>
                                  {i.name}
                                </span>
                              </div>
                              <div>
                                <progress
                                  value={i.value}
                                  min="0"
                                  max="2000"
                                  style={{ width: "200px", height: "15px" }}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>{i.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Stack direction="row" justifyContent="right" padding={1}>
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        border: "none",
                        color: "#15377A",
                        fontSize: "14px",
                      }}
                      endIcon={<EastIcon />}
                      variant="outlined"
                    >
                      Detail
                    </Button>
                  </Stack>
                </Box>
              ))}
            </Box>
          </Box>
          <Box>
            <Box
              p={1}
              sx={{
                backgroundColor: "#FFF",
                height: "635px",
                borderRadius: "4px",
              }}
            >
              <Stack>
                <Typography
                  fontSize="14px"
                  color="#292C33"
                  fontWeight="600"
                  p={1}
                >
                  Jam Sibuk
                </Typography>
                <Divider />
                <Chart
                  series={series}
                  options={options}
                  type="heatmap"
                  height="600px"
                  width="330px"
                />
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Demography;
