import { Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../../assets/logo_folia.png";

function Header() {
  return (
    <Grid container justify={`center`} alignItems="center">
      <Grid item xs={4}>
        <img src={logo} width={80} alt="" srcset="" />
      </Grid>
      <Grid item xs={8}>
        <Typography
          sx={{ color: "#FFF", fontSize: "30px", fontWeight: "600" }}
        >
          Rumah Folia
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center", marginBottom: "24px" }}>
        <Typography
          sx={{ color: "#FFF", fontSize: "16px", fontWeight: "400" }}
        >
          Please login to continue
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Header;
