import feeds from "./feeds";
import user from "./user";
import home from "./home";
import event from "./event";
import dashboard from "./dashboard";
import admin from "./admin";
import products from "./products";

const routes = [
  ...feeds,
  ...user,
  ...home,
  ...event,
  ...dashboard,
  ...admin,
  ...products,
];

export default routes;
