import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, Paper, Tab, Tabs } from "@mui/material";
import Published from "./_components/Published";
import dummy from "../../assets/dummy/feeds.json";
import axiosInstance from "utils/api/api";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Feeds = () => {
  const [value, setValue] = useState(0);
  const [articles, setArticles] = useState([]);

  const getArticles = () => {
    axiosInstance
      .get("/blogs/")
      .then((res) => {
        console.log(res);
        setArticles(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getArticles();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box flex="1">
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Article Database
        </Typography>
      </Stack>
      <Paper sx={{ padding: 3, height: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Published"
                {...a11yProps(0)}
                sx={{ textTransform: "capitalize" }}
              />
              {/* <Tab
                label="Drafted"
                {...a11yProps(1)}
                sx={{ textTransform: "capitalize" }}
              /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Published rows={articles} />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            Item Two
          </TabPanel> */}
        </Box>
      </Paper>
    </Box>
  );
};

export default Feeds;
