import React from "react";
import { Box, Stack, Typography, Paper } from "@mui/material";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import dummy from "../../../assets/dummy/highlight.json";

const highlightData = dummy.data;

const Highlight = () => {
  return (
    <Box flex="1" paddingX={1} mb={3}>
      <Typography fontSize="16px" color="#292C33" fontWeight="500" mb={1}>
        Highlight
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        {highlightData.map((item) => (
          <Box
            sx={{ borderRadius: "4px", width: "230px", paddingX: "1", backgroundColor:"#FFF" }}
            id={item.id}
          >
            <Stack padding={2}>
              <Typography fontSize="14px" color="#6D7688" fontWeight="400" gutterBottom>
                {item.title}
              </Typography>
              <Typography fontSize="24px" color="#292C33" fontWeight="500" gutterBottom>
                {item.value}
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" justifyContent="space-between">
                  {item.trend.name === "increasing" ? (
                    <NorthEastIcon sx={{ color: "#27AE60" }} fontSize="12px" />
                  ) : (
                    <SouthEastIcon
                      sx={{ color: "#E52929" }}
                      fontColor="#E52929"
                      fontSize="12px"
                    />
                  )}
                  <Typography
                    fontSize="12px"
                    color={
                      item.trend.name === "increasing" ? "#27AE60" : "#E52929"
                    }
                    fontWeight="600"
                  >
                    {item.trend.value}%
                  </Typography>
                </Stack>
                <Typography fontSize="12px" color="#6D7688" fontWeight="400">
                  vs Prev. 30 days
                </Typography>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Highlight;
