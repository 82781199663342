import React, { useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import logo from "../../../../assets/random_logo.png";
import dummy from "../../../../assets/dummy/sessionBreakdown.json";

const analyticData = dummy.data;

function Row(props) {
  const { item } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography>{moment(item.date).format("LL")}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{item.sessionId}</Typography>
        </TableCell>
        <TableCell>
          <Stack direction="row">
            <img
              src={logo}
              alt="fkbn"
              style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
            <Typography>{item.platform}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography>{item.duration}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <Stack padding={2}>
              <Stack direction="row" mb={2}>
                <Box mr={3}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#555D6B",
                    }}
                    id={item.sessionData.device}
                  >
                    {item.sessionData.device}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#6D7688",
                    }}
                  >
                    {item.sessionData.deviceCode}
                  </Typography>
                </Box>
                <Box mr={3}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#555D6B",
                    }}
                    id={item.sessionData.device}
                  >
                    {item.sessionData.provider}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#6D7688",
                    }}
                  >
                    {item.sessionData.providerCode}
                  </Typography>
                </Box>
                <Box mr={3}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#555D6B",
                    }}
                    id={item.sessionData.device}
                  >
                    {item.sessionData.city}, {item.sessionData.country}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#6D7688",
                    }}
                  >
                    {item.sessionData.coordinate}
                  </Typography>
                </Box>
              </Stack>
              <Divider />
              <Table>
                <TableHead sx={{ backgroundColor: "#F7F7F8" }}>
                  <TableRow>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#555D6B",
                        }}
                      >
                        ACTIVITY
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#555D6B",
                        }}
                      >
                        REMARKS
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "#F7F7F8" }}>
                  {item.visitedPage.map((i) => (
                    <TableRow key={i.id}>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#1247AF",
                        }}
                      >
                        {i.name}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#292C33",
                        }}
                      >
                        {i.remark}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const SessionBreakdown = () => {
  return (
    <>
      <Stack paddingX={2} paddingY={2} sx={{ borderRadius: "5px" }}>
        <Typography fontSize="18px" color="#0F50A9" fontWeight="600" mb={2}>
          Session Breakdown
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Box
              sx={{
                height: "auto",
                width: "1000px",
                borderRadius: "5px",
                backgroundColor: "#F9FAFA",
              }}
              mb={1}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#FFF",
                    border: "1px solid #DDDFE4",
                    borderRadius: "5px",
                  }}
                >
                  <TableRow sx={{ backgroundColor: "#F9FAFA" }}>
                    <TableCell />
                    <TableCell>
                      <Typography
                        fontSize="14px"
                        color="#292C33"
                        fontWeight="600"
                      >
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontSize="14px"
                        color="#292C33"
                        fontWeight="600"
                      >
                        Session
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontSize="14px"
                        color="#292C33"
                        fontWeight="600"
                      >
                        Platform
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontSize="14px"
                        color="#292C33"
                        fontWeight="600"
                      >
                        Duration
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    backgroundColor: "#FFF",
                    border: "1px solid #DDDFE4",
                    borderRadius: "5px",
                  }}
                >
                  {analyticData.map((item) => (
                    <Row key={item.id} item={item} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </Stack>
        {/* <Stack direction="row" justifyContent="space-between" mt={2}>
          <Box
            sx={{ height: "160px", width: "360px", backgroundColor: "#4E71FE", border:"1px dashed #FFF" }}
          ></Box>
          <Box
            sx={{ height: "160px", width: "620px", backgroundColor: "#4E71FE", border:"1px dashed #FFF" }}
          ></Box>
        </Stack> */}
      </Stack>
    </>
  );
};

export default SessionBreakdown;
