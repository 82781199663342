import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Typography,
  Grid,
  InputAdornment,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomTextField from "components/TextField";
import AddEventModal from "./_components/AddEventModal";
import ActiveEvents from "./ActiveEvents";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const EventList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box flex="1" position="relative">
      <AddEventModal open={modalOpen} setModalOpen={setModalOpen} />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography fontSize="24px" color="#505E50" fontWeight="600">
          Database Event
        </Typography>
        <Button
          variant="contained"
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#1247AF",
          }}
          onClick={() => setModalOpen(true)}
        >
          Buat Event
        </Button>
      </Stack>
      <Paper sx={{ padding: 3, height: "100%", width: "screen" }}>
        <Stack direction="row" justifyContent="space-between">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} marginLeft={3}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Active Event"
                sx={{ textTransform: "capitalize" }}
                {...a11yProps(0)}
              />
              <Tab
                label="Past Event"
                sx={{ textTransform: "capitalize" }}
                {...a11yProps(1)}
              />
              <Tab
                label="Draft"
                sx={{ textTransform: "capitalize" }}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <Stack direction="row" pr={3} sx={{ width: "50%" }}>
            <Grid mr={1} xs={8}>
              <CustomTextField name="" select />
            </Grid>
            <Grid mr={1} xs={8}>
              <CustomTextField name="" select />
            </Grid>
            <CustomTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Cari Konten"
            />
          </Stack>
        </Stack>
        <TabPanel value={value} index={0}>
          <ActiveEvents />
        </TabPanel>
        <TabPanel value={value} index={1}>
          No data
        </TabPanel>
        <TabPanel value={value} index={2}>
          No data
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default EventList;
