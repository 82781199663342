import React from "react";
import { Box, Stack, Typography, Paper, Grid } from "@mui/material";
import profilePicture from "../../../../assets/eventImage.png";

const EventData = () => {
  return (
    <Box flex="1" mt={2} sx={{ backgroundColor: "#F9FAFA" }}>
      <Paper
        sx={{
          padding: 3,
          marginLeft: "32px",
          marginRight: "54px",
        }}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={2}>
            <img src={profilePicture} alt="" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row">
              <Typography fontSize="18px" fontWeight="500">
                Bakti Sosial Lawan Covid-19
              </Typography>
            </Stack>
            <Stack mt={1}>
              <Stack mt={1} justifyContent="space-between">
                <Stack mb={1}>
                  <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                    Kategori
                  </Typography>
                  <button
                    style={{
                      color: "#2F5091",
                      width: "50px",
                      fontSize: "10px",
                      fontWeight: "700",
                      padding: "5px",
                      border: "none",
                      borderRadius: "5px",
                      backgroundColor: "#F2F2F2",
                    }}
                  >
                    <span>Umum</span>
                  </button>
                </Stack>
                <Stack mb={1}>
                  <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                    Batas Peserta
                  </Typography>
                  <Typography fontSize="14px" gutterBottom>
                    -
                  </Typography>
                </Stack>
                <Stack mb={1}>
                  <Typography
                    color="#6D7688"
                    fontWeight="500"
                    fontSize="12px"
                    gutterBottom
                  >
                    Tanggal
                  </Typography>
                  <Typography fontSize="14px">1 Januari 2022, 09:00</Typography>
                  <Typography fontSize="14px" gutterBottom>
                    1 Januari 2022, 18:00
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          {/* --------------------------------------------------- */}
          <Grid xs={1} />
          <Grid item xs={5}>
            <Stack mt={1} spacing={1}>
              <Stack justifyContent="space-between" mb={1}>
                <Typography fontSize="12px" color="#6D7688" fontWeight="500">
                  Platform
                </Typography>
                <button
                  style={{
                    color: "#2F5091",
                    width: "50px",
                    fontSize: "10px",
                    fontWeight: "700",
                    padding: "5px",
                    border: "none",
                    borderRadius: "5px",
                    backgroundColor: "#F2F2F2",
                  }}
                >
                  <span>Online</span>
                </button>
              </Stack>
              <Stack justifyContent="space-between" mb={1}>
                <Typography fontSize="12px" color="#6D7688" fontWeight="500">
                  Link
                </Typography>
                <a href="https://zoom.us/ajppei-oiwue-woiurew">
                  <Typography fontSize="14px" gutterBottom>
                    https://zoom.us/ajppei-oiwue-woiurew
                  </Typography>
                </a>
              </Stack>
              <Stack justifyContent="space-between" mb={1}>
                <Typography fontSize="12px" color="#6D7688" fontWeight="500">
                  Partisipan Terdaftar
                </Typography>
                <Typography fontSize="14px">50 orang</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default EventData;
