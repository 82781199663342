import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "components/Modal";
import CustomTextField from "components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "utils/api/api";

const adminCategory = [
  {
    value: "SuperAdmin",
    label: "SuperAdmin",
  },
  {
    value: "Admin",
    label: "Admin",
  },
];

const CreateAdmin = ({ open, setModalOpen, getAdmin }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("Admin");
  const token = JSON.parse(localStorage.getItem("folia"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => {
    setName("");
    setEmail("");
    setPassword("");
    setPhone("");
    setRole("Admin");
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      full_name: name,
      email: email,
      phone_number: phone,
      password: password,
      role: role,
    };

    axiosInstance
      .post("/admins/register-admin", data, authentication)
      .then((res) => {
        console.log(res);
        getAdmin();
        setModalOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Create New Admin
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={3} paddingY={2}>
            <Stack>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="role"
                  label="Role"
                  placeholder="Please select an admin type"
                  select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  {adminCategory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="name"
                  label="Full name"
                  placeholder="Please input a full name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="email"
                  label="Email"
                  placeholder="Please input a valid email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Please input a password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="phone_number"
                  label="Phone number"
                  placeholder="Please input a phone number"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#677A67",
                }}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default CreateAdmin;
