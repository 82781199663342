import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack
} from "@mui/material";
import CustomTextField from "components/TextField";
import AddIcon from '@mui/icons-material/Add'

const InformasiDasar = () => {
  return (
    <>
      <Box paddingX={2} >
        <Grid container spacing={1.5}>
          <Stack>
            <Grid item xs={12}>
              <CustomTextField
                name="event_name"
                label="Judul Event"
                placeholder="Masukkan judul event"
              />
            </Grid>
            <Grid item xs={6} mt={4}>
              <CustomTextField
                name="target"
                label="Target Peserta"
                placeholder="Masukkan target peserta"
                select
              />
              <FormControlLabel control={<Checkbox />} label="Publik" />
              <FormHelperText>Acara terbuka untuk umum.</FormHelperText>
            </Grid>
            <Grid item xs={12} mt={4}>
              <CustomTextField
                name="location"
                label="Lokasi Acara"
                placeholder="Cari Lokasi"
              />
              <FormControlLabel control={<Checkbox />} label="Acara Virtual" />
            </Grid>
            <FormHelperText>Isi pengaturan acara virtual di halaman ini.</FormHelperText>
            <Grid item xs={6} mt={4}>
              <CustomTextField
                name="participants"
                label="Jumlah Peserta"
                placeholder="Jumlah Peserta"
              />
            </Grid>
            <FormHelperText>Kosongkan, jika tidak ada batasan jumlah peserta.</FormHelperText>
            <Grid item xs={6} mt={4}>
              <CustomTextField
                name="date"
                label="Tanggal dan Waktu Event"
                placeholder="Tanggal"
                type="date"
              />
            </Grid>
            <Grid xs={6} mt={2}>
            <Button
              sx={{
                textTransform: "capitalize",
              }}
              startIcon={<AddIcon />}
              variant="outlined"
            >
              Tanggal Selesai
            </Button>
            </Grid>
          </Stack>
        </Grid>
      </Box>
    </>
  );
};

export default InformasiDasar;
