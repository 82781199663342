import React, { useState } from "react";
import { Box, Stack, Typography, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import dummy from "../../../../assets/dummy/participant.json";

const columns = [
  {
    field: "profile_picture",
    headerClassName: "header-font",
    headerName: "P",
    width: 50,
    renderCell: (params) => (
      <img
        alt="profile"
        src={params.value}
        style={{ height: "30px", width: "30px", borderRadius: "5px" }}
      />
    ),
  },
  {
    field: "full_name",
    headerClassName: "header",
    headerName: "Partisipan",
    flex: 8,
  },
  {
    field: "createdAt",
    headerClassName: "header",
    headerName: "Tanggal Mendaftar",
    flex: 6,
    renderCell: ({ value }) => moment(value).format("LL"),
  },
  {
    field: "email",
    headerClassName: "header",
    headerName: "Email",
    flex: 4,
  },
  {
    field: "telephone",
    headerClassName: "header",
    headerName: "No. Telepon",
    flex: 4,
  },
];

const rows = dummy.data;

const ParticipantList = () => {
  const [pageSize, setPageSize] = useState(5);

  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA", marginTop: "1rem" }}>
      <Paper
        sx={{
          padding: 3,
          marginLeft: "32px",
          marginRight: "54px",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="16px" color="#505E50" fontWeight="500">
            Daftar Partisipan
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" marginTop="1rem">
          <Box
            sx={{
              "& .header": {
                backgroundColor: "#F9FAFA",
                fontWeight: "600",
              },
              "& .header-font": {
                backgroundColor: "#F9FAFA",
                color: "#F9FAFA",
              },
              width: "100%",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              autoHeight
              header
              isRowSelectable={() => false}
              pagination
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

export default ParticipantList;
