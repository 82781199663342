import React from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import dummy from "../../../assets/dummy/growthMetric.json";

const dataSeries = dummy.data;

const options = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  title: {
    text: "",
    align: "left",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: ["Week I", "Week 2", "Week 3", "Week 4", "Week 5"],
  },
};

const GrowthMetric = () => {
  return (
    <Box flex="1" paddingX={1} mb={3}>
      <Typography fontSize="16px" color="#292C33" fontWeight="500" mb={1}>
        Growth Metric
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        {dataSeries.map((item) => (
          <Box
            sx={{
              borderRadius: "4px",
              width: "500px",
              paddingX: "1",
              backgroundColor: "#FFF",
            }}
            id={item.id}
          >
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                paddingX={2}
                paddingY={1}
              >
                <Typography
                  fontSize="14px"
                  color="#292C33"
                  fontWeight="600"
                  sx={{ paddingTop: "5px" }}
                >
                  {item.title}
                </Typography>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    border: "none",
                    color: "#15377A",
                    fontSize: "14px",
                  }}
                  variant="outlined"
                >
                  Lihat Detail
                </Button>
              </Stack>
              <Divider />
              <Box mt={1}>
                <Chart options={options} series={item.series} />
              </Box>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default GrowthMetric;
