import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Chart from "react-apexcharts";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Dashboard = () => {
  const series = [
    {
      name: "data A",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "date",
      categories: [
        "2021-09-19",
        "2021-09-20",
        "2021-09-21",
        "2021-09-22",
        "2021-09-23",
        "2021-09-24",
        "2021-09-25",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
  const seriesHeatMap = [
    {
      name: "10 AM",
      data: [
        {
          x: "Sen",
          y: 49,
        },
        {
          x: "Sel",
          y: 42,
        },
        {
          x: "Rab",
          y: 43,
        },
        {
          x: "Kam",
          y: 60,
        },
        {
          x: "Jum",
          y: 43,
        },
        {
          x: "Sab",
          y: 43,
        },
        {
          x: "Min",
          y: 43,
        },
      ],
    },
    {
      name: "10 AM",
      data: [
        {
          x: "Sen",
          y: 43,
        },
        {
          x: "Sel",
          y: 43,
        },
        {
          x: "Rab",
          y: 43,
        },
        {
          x: "Kam",
          y: 43,
        },
        {
          x: "Jum",
          y: 43,
        },
        {
          x: "Sab",
          y: 43,
        },
        {
          x: "Min",
          y: 43,
        },
      ],
    },
    {
      name: "10 AM",
      data: [
        {
          x: "Sen",
          y: 43,
        },
        {
          x: "Sel",
          y: 43,
        },
        {
          x: "Rab",
          y: 43,
        },
        {
          x: "Kam",
          y: 43,
        },
        {
          x: "Jum",
          y: 43,
        },
        {
          x: "Sab",
          y: 43,
        },
        {
          x: "Min",
          y: 43,
        },
      ],
    },
    {
      name: "10 AM",
      data: [
        {
          x: "Sen",
          y: 43,
        },
        {
          x: "Sel",
          y: 43,
        },
        {
          x: "Rab",
          y: 43,
        },
        {
          x: "Kam",
          y: 43,
        },
        {
          x: "Jum",
          y: 43,
        },
        {
          x: "Sab",
          y: 43,
        },
        {
          x: "Min",
          y: 43,
        },
      ],
    },
    {
      name: "10 AM",
      data: [
        {
          x: "Sen",
          y: 43,
        },
        {
          x: "Sel",
          y: 43,
        },
        {
          x: "Rab",
          y: 43,
        },
        {
          x: "Kam",
          y: 43,
        },
        {
          x: "Jum",
          y: 43,
        },
        {
          x: "Sab",
          y: 43,
        },
        {
          x: "Min",
          y: 43,
        },
      ],
    },
  ];
  const optionsHeatmap = {
    chart: {
      height: 350,
      type: "heatmap",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#008FFB"],
    title: {
      text: "Jam Sibuk",
    },
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  return (
    <>
      <Box sx={{ flexGrow: 1}} padding={4}>
        {/* BreadCumb */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/">
                    Home
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    href="/getting-started/installation/"
                  >
                    Dashboard
                  </Link>
                </Breadcrumbs>
              </div>
            </Item>
          </Grid>
        </Grid>
        {/* End Breadcumb */}

        <Grid
          container
          spacing={2}
          sx={{ marginTop: "50px", overflow: "scroll", minWidth: "100%" }}
        >
          <Grid item xs={8}>
            <Item>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontSize: "16px", color: "#235DD1" }}>
                    App Trafic
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    Number of logged in users
                  </Typography>
                </Box>
                <FormControl sx={{ minWidth: "200px" }}>
                  <InputLabel id="demo-simple-select-label">30 Days</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Month"
                  >
                    <MenuItem value={30}>30 Days</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <div id="chart">
                <Chart
                  options={options}
                  series={series}
                  type="area"
                  height={350}
                />
              </div>
            </Item>
            {/* Daily */}
            <Grid container spacing={2} sx={{ marginTop: "50px" }}>
              <Grid item xs={4}>
                <Item sx={{ textAlign: "left" }}>
                  <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Daily User
                    </Typography>
                    <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                      7.090
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Weekly User
                    </Typography>
                    <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                      98.090
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Monthly User
                    </Typography>
                    <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                      123.090
                    </Typography>
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Item sx={{ minHeight: "100px" }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Total User
                    </Typography>
                    <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                      600K
                    </Typography>
                  </Item>
                  <Item sx={{ marginTop: "23px", minHeight: "100px" }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Session Duration
                    </Typography>
                    <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                      1m 52s
                    </Typography>
                  </Item>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Item sx={{ minHeight: "100px" }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Gender
                    </Typography>
                    <BorderLinearProgress
                      variant="determinate"
                      value={50}
                      sx={{ marginTop: "10px" }}
                    />
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          16.000
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>Men</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          16.000
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>Women</Typography>
                      </Box>
                    </Box>
                  </Item>
                  <Item sx={{ marginTop: "23px", minHeight: "100px" }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Session
                    </Typography>
                    <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                      1m 52s
                    </Typography>
                  </Item>
                </Box>
              </Grid>
            </Grid>
            {/* End Daily */}
          </Grid>
          <Grid item xs={4}>
            <Chart
              options={optionsHeatmap}
              series={seriesHeatMap}
              type="heatmap"
              height={350}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
