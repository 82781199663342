import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Stack } from "@mui/material";
import Sidebar from "../../components/Sidebar";
import AdminSidebar from "components/AdminSidebar";
import routes from "routes";
import Navbar from "../../components/Navbar";

function Main() {
  const admin = JSON.parse(localStorage.getItem("admin_data"));
  console.log(admin.role);
  return (
    <div style={{ position: "relative" }}>
      {/* Navbar ----------------------------- */}
      <Routes>
        {routes.map((route) => {
          if (route.navbar)
            return <Route path={route.path} element={<Navbar />} />;
        })}
      </Routes>

      {/* ---------------------------------- */}
      <Stack
        direction="row"
        sx={{
          top: "56px",
          height: "100vh",
        }}
      >
        {/* Render Sidebar */}
        <Stack sx={{ minHeight: "100vh" }}>
          <Routes>
            {routes.map((route) => {
              if (route.sidebar)
                return (
                  <Route
                    path={route.path}
                    element={
                      admin.role === "SuperAdmin" ? (
                        <Sidebar />
                      ) : (
                        <AdminSidebar />
                      )
                    }
                  />
                );
            })}
          </Routes>
        </Stack>

        {/* Render Page */}
        <Stack
          sx={{
            width: "100%",
            height: "100vh",
            overflow: "scroll",
            paddingTop: "56px",
          }}
        >
          <Routes>
            {routes.map((route) => {
              return <Route path={route.path} element={route.component} />;
            })}
          </Routes>
        </Stack>
      </Stack>
    </div>
  );
}

export default Main;
