import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "components/Modal";
import dummy from "../../../assets/dummy/account.json";
import ClearIcon from "@mui/icons-material/Clear";
import trash from '../../../assets/trash-alt.png';

const account = dummy.data;

const UserInvalidModal = ({ open, setModalOpen }) => {
    return (
        <ModalCustom
            open={open}
            handleClose={() => setModalOpen(false)}
            cardStyle={{ borderRadius: 2, width: 658 }}
        >
            {/* Header */}
            <Box>
                <Box
                    sx={{
                        p: 2,
                    }}
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize="16px">
                           Oopps.. Email Atau Password Salah
                        </Typography>
                        <Grid item>
                            <ClearIcon sx={{ cursor: "pointer" }} onClick={() => setModalOpen(false)} />
                        </Grid>
                    </Stack>
                </Box>
                <Divider />
            </Box>
        </ModalCustom>
    );
};

export default UserInvalidModal;
