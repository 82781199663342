import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Main from "routes/main";
import Login from "pages/Login";
import { useState, useEffect } from "react";

function App() {
  const [login, setLogin] = useState({
    token: JSON.parse(localStorage.getItem("folia")),
  });

  // console.log(login);

  const theme = createTheme({
    typography: {
      fontFamily: ["Arial"].join(","),
    },
  });

  useEffect(() => {
    setLogin({ token: JSON.parse(localStorage.getItem("folia")) });
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {login?.token ? <Main /> : <Login />}
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
