import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Stack,
} from "@mui/material";
import CustomTextField from "components/TextField";

const KategoriTiket = () => {
  return (
    <>
      <Box paddingX={2}>
        <Grid container spacing={1.5}>
          <Stack>
            <Grid item xs={12}>
              <RadioGroup row aria-label="" name="">
                <FormControlLabel
                  value="free"
                  control={<Radio />}
                  label="Gratis"
                  sx={{ marginRight: "150px" }}
                />
                <FormControlLabel
                  value="paid"
                  control={<Radio />}
                  label="Berbayar"
                />
              </RadioGroup>
            </Grid>
            <Stack direction="row">
              <Grid item xs={6} mt={2} mr={1}>
                <CustomTextField name="total" label="Jumlah" placeholder="0" />
              </Grid>
              <Grid item xs={6} mt={2}>
                <CustomTextField name="price" label="Harga" placeholder="Rp" />
              </Grid>
            </Stack>
            <Stack direction="row">
              <Grid item xs={7} mt={4} mr={1}>
                <CustomTextField
                  name="date"
                  label="Tanggal Mulai"
                  placeholder="Tanggal"
                  type="date"
                />
              </Grid>
              <Grid item xs={2} mt={4}>
                <CustomTextField
                  name="date"
                  label="Waktu"
                  placeholder="00:00"
                />
              </Grid>
            </Stack>
            <Stack direction="row">
              <Grid item xs={7} mt={4} mr={1}>
                <CustomTextField
                  name="date"
                  label="Tanggal Selesai"
                  placeholder="Tanggal"
                  type="date"
                />
              </Grid>
              <Grid item xs={2} mt={4}>
                <CustomTextField
                  name="date"
                  label="Waktu"
                  placeholder="00:00"
                />
              </Grid>
            </Stack>
            <Typography mt={1} sx={{fontWeight:"400", fontSize:"14px", color:"#6D7688"}}>Tanggal maksimal penjualan bergantung pada tanggal selesainya event.</Typography>
          </Stack>
        </Grid>
      </Box>
    </>
  );
};

export default KategoriTiket;
