import React, { useState } from "react";
import logo from "assets/logo_folia.png";
import {
  Stack,
  Box,
  Button,
  Avatar,
  Typography,
  ClickAwayListener,
  Fade,
  Popper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

function Navbar() {
  const navigate = useNavigate();

  const Logout = () => {
    localStorage.removeItem("folia");
    navigate("/");
    return window.location.reload();
  };
  return (
    <Stack
      height="56px"
      width="100%"
      direction="row"
      justifyContent="space-between"
      sx={{
        position: "fixed",
        top: 0,
        color: "#1B1B1B",
        backgroundColor: "#FFF",
        zIndex: "100",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Box
          width="240px"
          paddingX="16px"
          sx={{ backgroundColor: "#677A67", borderTopRightRadius: "10px" }}
        >
          <Stack direction="row" alignItems="center" height="100%" spacing={1}>
            <Avatar src={logo} sx={{ borderRadius: 0 }} />
            <Typography
              fontSize="20px"
              color="white"
              fontWeight="400"
              sx={{ letterSpacing: "2px" }}
            >
              Rumah Folia
            </Typography>
          </Stack>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          paddingRight="54px"
          paddingY={1}
        >
          <LogoutIcon
            sx={{ cursor: "pointer", color: "#677A67" }}
            onClick={Logout}
          />
          <Avatar src={logo} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Navbar;
