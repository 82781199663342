import React, { useState } from "react";
import { Box, Button, Stack, Typography, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Chart from "react-apexcharts";
import moment from "moment";
import dummy from "../../../../assets/dummy/memberActivity.json";
import heatmap from "../../../../assets/dummy/activityData.json";

const series = heatmap.series;

const options = {
  plotOptions: {
    heatmap: {
      shadeIntensity: 0.5,
      radius: 2,
      enableShades: false,
      useFillColorAsStroke: false,
      distributed: true,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 0,
            name: "Inactive",
            color: "#CBD9F6",
          },
          {
            from: 0.1,
            to: 0.75,
            name: "Low",
            color: "#487BE0",
          },
          {
            from: 0.76,
            to: 1.5,
            name: "Medium",
            color: "#235DD1",
          },
          {
            from: 1.6,
            to: 2.1,
            name: "High",
            color: "#1247AF",
          },
          {
            from: 2.2,
            to: 3,
            name: "Extreme",
            color: "#15377A",
          },
        ],
      },
    }
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
  },
  title: {
    text: "",
  },
};

const columns = [
  {
    field: "date",
    headerClassName: "super-app-theme--header",
    headerName: "Session Start",
    flex: 4,
    renderCell: ({ value }) => moment(value).format("DD/MM/YYYY"),
  },
  {
    field: "duration",
    headerClassName: "super-app-theme--header",
    headerName: "Duration",
    flex: 1,
  },
];

const rows = dummy.data;

const Activity = () => {
  const [pageSize, setPageSize] = useState(5);

  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA", marginTop: "1rem" }}>
      <Paper
        sx={{
          padding: 3,
          marginLeft: "32px",
          marginRight: "54px",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="16px" color="#505E50" fontWeight="500">
            Aktivitas User
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              sx={{
                textTransform: "capitalize",
                border: "none",
                color: "#15377A",
                fontSize: "12px",
              }}
              variant="outlined"
            >
              Lihat Detail
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" marginTop="1rem">
          <Box
            sx={{
              marginRight: "15px",
            }}
          >
            <Chart
              series={series}
              options={options}
              type="heatmap"
              height="400px"
              width="400px"
            />
          </Box>
          <Box
            sx={{
              "& .super-app-theme--header": {
                backgroundColor: "#F9FAFA",
              },
              width: "100%",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              autoHeight
              header
              isRowSelectable={() => false}
              pagination
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

export default Activity;
