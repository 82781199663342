import React,{useState} from "react";
import { Box, Stack, Typography, Paper, Grid } from "@mui/material";
import profilePicture from "../../../../assets/sample-profile-picture.png";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CheckIcon from "@mui/icons-material/Check";
import MailIcon from "@mui/icons-material/Mail";

const PersonalDetail = () => {
  const [dataUser, setDataUser] = useState(
    JSON.parse(localStorage.getItem("data_user"))
  );
  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA" }}>
      <Paper
        sx={{
          padding: 3,
          marginLeft: "32px",
          marginRight: "54px",
        }}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={2}>
            <img src={profilePicture} alt="" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row">
              <Typography fontSize="18px" fontWeight="500">
                Ryan Wicaksono M.Pd
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              mt={2}
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center">
                <PhoneIphoneIcon fontSize="small" />
                <Typography fontSize="14px" ml={1}>
                  +62-8987-98791
                </Typography>
              </Stack>
              <CheckIcon fontSize="small" color="success" />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              mt={1}
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center">
                <MailIcon fontSize="small" />
                <Typography fontSize="14px" ml={1}>
                  ryan.wicaksono@mail.com
                </Typography>
              </Stack>
              <CheckIcon fontSize="small" color="success" />
            </Stack>
            <Stack mt={1.5}>
              <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                Tanggal Terdaftar
              </Typography>
              <Typography fontSize="14px">16/08/2013</Typography>
            </Stack>
            <Stack mt={3}>
              <Typography fontSize="16px" color="#505E50" fontWeight="500">
                Informasi Perangkat
              </Typography>
              <Stack direction="row" mt={1} justifyContent="space-between">
                <Stack>
                  <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                    Active Device
                  </Typography>
                  <Typography fontSize="14px">Galaxy S20</Typography>
                </Stack>
                <Stack>
                  <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                    Last Active
                  </Typography>
                  <Typography fontSize="14px">16/08/2013</Typography>
                </Stack>
              </Stack>
              {/* fans */}
              {
                dataUser.role == "adminfans" ?
                  <>
                    <Stack direction="row" mt={1} justifyContent="space-between">
                      <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                        IMEI
                      </Typography>
                      <Typography fontSize="14px">1203981029381098</Typography>
                    </Stack>
                    <Stack direction="row" mt={1} justifyContent="space-between">
                      <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                        MAC ADDRESS
                      </Typography>
                      <Typography fontSize="14px">15:A3:90:48:D0</Typography>
                    </Stack>
                    <Stack direction="row" mt={1} justifyContent="space-between">
                      <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                        IP ADDRESS
                      </Typography>
                      <Typography fontSize="14px">192.168.0.1</Typography>
                    </Stack>
                  </>
                  : ''
              }

            </Stack>
            {/* end fans */}
          </Grid>
          {/* --------------------------------------------------- */}
          <Grid xs={1} />
          <Grid item xs={5}>
            <Typography fontSize="16px" color="#505E50" fontWeight="500">
              Data Pribadi
            </Typography>
            <Stack mt={1} spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="12px" color="#6D7688" fontWeight="500">
                  Profesi
                </Typography>
                <Typography fontSize="14px">Software Engineer</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="12px" color="#6D7688" fontWeight="500">
                  NIK
                </Typography>
                <Typography fontSize="14px">3579023003750000</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="12px" color="#6D7688" fontWeight="500">
                  Tanggal Lahir
                </Typography>
                <Typography fontSize="14px">16/08/2013</Typography>
              </Stack>
            </Stack>
            <Stack mt={1.5}>
              <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                Alamat
              </Typography>
              <Typography fontSize="14px">
                Jl. Mayor Suryotomo No.31, Ngupasan, Kec. Gondomanan, Kota
                Yogyakarta, Daerah Istimewa Yogyakarta 55122
              </Typography>
            </Stack>
            <Grid container>
              <Grid item xs={6}>
                <Stack mt={1.5}>
                  <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                    Provinsi
                  </Typography>
                  <Typography fontSize="14px">D.I Yogyakarta</Typography>
                </Stack>
                <Stack mt={1.5}>
                  <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                    Kecamatan
                  </Typography>
                  <Typography fontSize="14px">Gondomanan</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack mt={1.5}>
                  <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                    Kota
                  </Typography>
                  <Typography fontSize="14px">Kota Yogyakarta</Typography>
                </Stack>
                <Stack mt={1.5}>
                  <Typography color="#6D7688" fontWeight="500" fontSize="12px">
                    Kelurahan
                  </Typography>
                  <Typography fontSize="14px">Ngupasan</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default PersonalDetail;
