import React from "react";
import { Box } from "@mui/material";
import { EventData, ParticipantList } from "./DetailComponents";

const EventDetail = () => {
  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA" }}>
      <EventData />
      <ParticipantList />
    </Box>
  );
};

export default EventDetail;