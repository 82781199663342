import React from "react";
import { Box, Stack, Typography, Paper } from "@mui/material";
import dummy from "../../../../assets/dummy/memberPurchasing.json";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CalculateIcon from "@mui/icons-material/Calculate";
import PieChartIcon from "@mui/icons-material/PieChart";
import LockIcon from "@mui/icons-material/Lock";

const data = dummy.data;

const Purchasing = () => {
  return (
    <Box
      flex="1"
      sx={{
        backgroundColor: "#F9FAFA",
        marginTop: "1rem",
        marginBottom: "1rem",
        width: "430px",
      }}
    >
      <Paper
        sx={{
          padding: 3,
          marginLeft: "32px",
          marginRight: "54px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          marginBottom="16px"
        >
          <Typography fontSize="16px" color="#505E50" fontWeight="500">
            Purchasing Power
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          marginBottom="4px"
        >
          <Typography fontSize="12px" color="#6D7688" fontWeight="400">
            <ShoppingCartIcon fontSize="5px" /> Shopping Cart
          </Typography>
          <Typography fontSize="12px" color="#6D7688" fontWeight="400">
            Value
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          marginBottom="8px"
        >
          <Typography fontSize="14px" color="#292C33" fontWeight="400" marginLeft="16px">
            {data.cartItems} Item
          </Typography>
          <Typography fontSize="14px" color="#292C33" fontWeight="400">
            Rp {data.cartValue}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          marginBottom="4px"
        >
          <Typography fontSize="12px" color="#6D7688" fontWeight="400">
            <CalculateIcon fontSize="5px" /> Checkout
          </Typography>
          <Typography fontSize="12px" color="#6D7688" fontWeight="400">
            Value
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          marginBottom="8px"
        >
          <Typography fontSize="14px" color="#292C33" fontWeight="400" marginLeft="16px">
            {data.checkoutItems} Item
          </Typography>
          <Typography fontSize="14px" color="#292C33" fontWeight="400">
            Rp {data.checkoutValue}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          marginBottom="28px"
        >
          <Typography fontSize="12px" color="#6D7688" fontWeight="400">
            <PieChartIcon fontSize="5px" /> Cart-to-Checkout Ratio
          </Typography>
          <Typography fontSize="14px" color="#292C33" fontWeight="400">
            {data.ratio} %
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="12px" color="#6D7688" fontWeight="400">
          <LockIcon fontSize="5px" /> Total Spending
          </Typography>
          <Typography fontSize="14px" color="#292C33" fontWeight="600">
            Rp {data.total}
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};

export default Purchasing;
