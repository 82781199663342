import { Compose, Feeds } from "pages";
import ArticleDetails from "pages/Feeds/_components/ArticleDetails";

const root = "/content";

const feeds = [
  {
    path: `${root}/published-content`,
    component: <Feeds />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/compose`,
    component: <Compose />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/published-content/:articleId`,
    component: <ArticleDetails />,
    sidebar: true,
    navbar: true,
  },
];

export default feeds;
