import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  InputAdornment,
  Stack,
} from "@mui/material";
import ModalCustom from "components/Modal";
import CustomTextField from "components/TextField";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";

const AddMemberModal = ({ open, setModalOpen }) => {
  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "80%" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Pembuatan Akun Baru
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <Box
        sx={{
          p: 2,
          maxHeight: "400px",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            padding: "20px",
            borderRadius: "4px",
            backgroundColor: "#fafafa",
            color: "#6D7688",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                mb={2}
                sx={{ fontWeight: "600", fontSize: "16px", color: "#292C33" }}
              >
                Foto Profil
              </Typography>
            </Box>
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
              />
              <Box
                sx={{
                  textAlign: "center",
                  border: "3px dashed #6D7688",
                  borderRadius: "5px",
                  backgroundColor: "#fafafa",
                  color: "#6D7688",
                  marginBottom: "20px",
                  width: "96px",
                  height: "82px",
                  padding: "24px 0",
                  cursor: "pointer",
                }}
              >
                <AddIcon sx={{ color: "#6D7688" }} />
              </Box>
            </label>
            <Typography>
              Format gambar .jpg, .jpeg, .png, dan ukuran minimum 300 X 300px
            </Typography>
          </Stack>
        </Box>
        <Typography fontSize="16px" fontWeight="500" mb={2}>
          Biodata Singkat
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <CustomTextField
              name="full_name"
              label="Nama Lengkap"
              placeholder="Masukkan nama lengkap"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name="nik"
              label="Nomor Induk Kependudukan"
              placeholder="Masukkan NIK"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name="city_of_birth"
              label="Tempat (Kota) Lahir"
              placeholder="Tempat Lahir"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name="date_of_birth"
              label="Tanggal Lahir"
              placeholder="Tanggal Lahir"
              type="date"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name="occupation"
              label="Profesi"
              placeholder="Pekerjaan"
            />
          </Grid>
        </Grid>
        <Typography fontSize="16px" fontWeight="500" mb={2} mt={2}>
          Informasi Kontak
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <CustomTextField
              name="telephone"
              label="Nomor HP"
              placeholder="Nomor Handphone"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name="email"
              label="Email"
              placeholder="Alamat Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Typography fontSize="16px" fontWeight="500" mb={2} mt={2}>
          Alamat
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <CustomTextField
              name="province"
              label="Provinsi"
              placeholder="Provinsi"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name="city" label="Kota" placeholder="Kota" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name="region"
              label="Kelurahan/Desa"
              placeholder="Kelurahan"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name="rt/rw" label="RT/RW" placeholder="RT/RW" />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="address"
              label="Alamat (Domisili)"
              placeholder="Nama Jalan/Perumahan"
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Divider />
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="right">
            <Button
              sx={{
                textTransform: "capitalize",
                marginRight: "16px",
              }}
              variant="outlined"
              onClick={() => setModalOpen(false)}
            >
              Batalkan
            </Button>
            <Button
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#1247AF",
                fontFamily: "Inter",
              }}
              variant="contained"
            >
              Simpan
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalCustom>
  );
};

export default AddMemberModal;
