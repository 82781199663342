import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ViewIcon from "@mui/icons-material/Visibility";
import LikeIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import moment from "moment";
import dummy from "../../../../assets/dummy/memberInteraction.json";

const Icon = {
  color: "#C1C5CD",
  marginRight: "5px",
};

const interactionRow = dummy.interaction;

const TotalInteraction = ({ item }) => {
  if (item.id === "view") {
    return <ViewIcon sx={{ color: "#C1C5CD" }} />;
  } else if (item.id === "like") {
    return <LikeIcon sx={{ color: "#C1C5CD" }} />;
  } else {
    return <ShareIcon sx={{ color: "#C1C5CD" }} />;
  }
};

const columns = [
  {
    field: "date",
    headerClassName: "header",
    headerName: "Tanggal",
    width: 250,
    renderCell: ({ value }) => moment(value).format("LLL"),
  },
  {
    field: "title",
    headerClassName: "header",
    headerName: "Judul",
    width: 600,
  },
  {
    field: "action",
    headerClassName: "header",
    headerName: "Aktivitas",
    width: 100,
    renderCell: ({ value }) => {
      if (value === "View") {
        return (
          <>
            <ViewIcon style={Icon} /> <span>{value}</span>
          </>
        );
      } else if (value === "Like") {
        return (
          <>
            <LikeIcon style={Icon} /> <span>{value}</span>
          </>
        );
      } else {
        return (
          <>
            <ShareIcon style={Icon} /> <span>{value}</span>
          </>
        );
      }
    },
  },
];

const rows = dummy.data;

const Interaction = () => {
  const [pageSize, setPageSize] = useState(5);

  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA", marginTop: "1rem" }}>
      <Paper
        sx={{
          padding: 3,
          marginLeft: "32px",
          marginRight: "54px",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="16px" color="#505E50" fontWeight="500">
            Interaksi Konten
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              sx={{
                textTransform: "capitalize",
                border: "none",
                color: "#15377A",
                fontSize: "12px",
              }}
              variant="outlined"
            >
              Lihat Detail
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" marginTop="1rem">
          <Box
            sx={{
              width: "400px",
              marginRight: "15px",
              "& .header": {
                backgroundColor: "#F9FAFA",
              },
            }}
          >
            <Table>
              <TableHead
                sx={{
                  backgroundColor: "#FFF",
                  border: "1px solid #DDDFE4",
                  borderRadius: "5px",
                }}
              >
                <TableRow sx={{ backgroundColor: "#F9FAFA" }}>
                  <TableCell>
                    <Typography
                      fontSize="14px"
                      color="#292C33"
                      fontWeight="600"
                    >
                      Total Interaction
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="12px"
                      color="#6D7688"
                      fontWeight="600"
                      sx={{
                        textTransform: "uppercase",
                      }}
                    ></Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  backgroundColor: "#FFF",
                  border: "1px solid #DDDFE4",
                  borderRadius: "5px",
                }}
              >
                {interactionRow.map((item) => (
                  <TableRow id={item.id} key={item.id}>
                    <TableCell>
                      <Stack direction="row">
                        <TotalInteraction item={item} />
                        <Typography ml={1} sx={{ color: "#1247AF" }}>
                          {item.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography ml={2}>{item.value}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Stack
              direction="row"
              justifyContent="space-between"
              mt={1}
              sx={{
                backgroundColor: "#F9FAFA",
                border: "1px solid #F1F1F1",
                borderRadius: "3px",
                boxShadow: "0px 1px 0px #F1F1F1",
              }}
              px={1}
            >
              <h5>Engagement Rate</h5>
              <h5>{dummy.engagementRate}</h5>
            </Stack>
          </Box>
          <Box
            sx={{
              "& .header": {
                backgroundColor: "#F9FAFA",
              },
              width: "100%",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              autoHeight
              header
              isRowSelectable={() => false}
              pagination
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

export default Interaction;
