import React, { useState } from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Icon = {
  marginRight: "5px",
  color: "#A4AAB6",
  paddingTop: "12px",
};

const columns = [
  {
    field: "title",
    headerClassName: "header",
    headerName: "Article Title",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => <span style={{ paddingLeft: 6 }}>{value}</span>,
  },
  {
    field: "createdAt",
    headerClassName: "header",
    headerName: "Published Date",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <span style={{ paddingLeft: 6 }}>{moment(value).format("LL")}</span>
    ),
  },
];

const Published = ({ rows }) => {
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  return (
    <Box flex="1">
      <Box
        sx={{
          "& .header": {
            backgroundColor: "#F9FAFA",
          },
          "& .header-font": {
            backgroundColor: "#F9FAFA",
            color: "#F9FAFA",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          autoHeight
          header
          getRowId={(rows) => rows._id}
          pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 15, 25]}
          onRowClick={(rows) =>
            navigate(`/content/published-content/${rows.id}`)
          }
        />
      </Box>
    </Box>
  );
};

export default Published;
